import React, { FC, useEffect, useState } from 'react';
import './header.scss';
import Logo from "./../../assets/images/logo.png";
import Hamburger from "./../../assets/images/hamburger.svg";
import LeftArrow from "./../../assets/images/left-arrow.svg";
import Instagram from "./../../assets/images/instagram.svg";
import Twitter from "./../../assets/images/twitter.svg";
import Discord from "./../../assets/images/discord.svg";
import { MintButton } from '../mint-button/MintButton';
import { WalletButton } from '../wallet-button/WalletButton';
import WalletIcon from "./../../assets/images/wallet-icon.svg";
import { MobileMintModalBox } from '../mobile-mint-modal/MobileMintModal';
import * as Scroll from 'react-scroll';
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

export const Header:FC = () => {
    // let offsetcontrol = -326;
    // const [offsetcontrol, setoffset] = useState(-326);
    const [isActive, setActive] = useState(false);
    const [address, setAddress] = useState("");
    const [correctChain, setCorrectChain] = useState(false);
    const [open,setOpen] = useState<boolean>(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const [activeButton, setActiveButton] = useState<string>("");
    const changeActiveButton = (buttonName: string) => {
        console.log("buttonNAme",buttonName)
        setActiveButton(buttonName);
        hideSideNav();
        const selectedSection = document.getElementById(buttonName)
        const activeAnchor =  document.getElementById(`#${buttonName}`)
        const selectedAnchor = document.getElementsByClassName("active")
        selectedAnchor[0].classList.remove("active")
        // activeAnchor?.classList.add("active")
        const selectedPaddClass = document.getElementsByClassName("onload-padd-top")
        if(selectedPaddClass.length>0){
            selectedPaddClass[0].classList.remove("onload-padd-top")
        }
        if(window.scrollY==0){
            selectedSection?.classList.add("onload-padd-top")
        }else {
            selectedSection?.classList.remove("onload-padd-top")
        }
        setActive(false);
        document.body.classList.remove("hidden");
    };
    const showSideNav = () => {
        setActive(true);
        document.body.classList.add("hidden");
    };
    const hideSideNav = () => {
        // console.log(window.scrollY, 'window.scrollY');
        // if(window.scrollY>0){
        //     setoffset(-125);
        // }
        // else {
        //     setoffset(-326);
        // }
        // console.log(offsetcontrol)
        setActive(false);
        document.body.classList.remove("hidden");
    };
    // const applyingClass = () => {
    //     const header: HTMLElement = document.getElementById(
    //       "header"
    //     ) as HTMLElement;
    //     const sticky = header.offsetTop;
    //     if (window.pageYOffset > sticky) {
    //       header.classList.add("scroll-top");
    //     } else {
    //       header.classList.remove("scroll-top");
    //     }
    //   };
    //   useEffect(() => {
    //     window.addEventListener("scroll", applyingClass);
    //   });
    function scrollStop (callback, refresh = 66) {

        // Make sure a valid callback was provided
        if (!callback || typeof callback !== 'function') return;
    
        // Setup scrolling variable
        let isScrolling;
    
        // Listen for scroll events
        window.addEventListener('scroll', function (event) {
    
            // Clear our timeout throughout the scroll
            window.clearTimeout(isScrolling);
    
            // Set a timeout to run after scrolling ends
            isScrolling = setTimeout(callback, refresh);
    
        }, false);
    
    }
    // let paddedElement 
    // scrollStop(()=>{
    //      paddedElement = document.getElementsByClassName("onload-padd-top")
        

    // })
    window.addEventListener("scroll",()=>{
        const header = document.getElementById("header")
        if(window.scrollY>0){
            header?.classList.add("scroll-top")
        }else{
            header?.classList.remove("scroll-top")
        }
    })
    window.addEventListener("wheel",()=>{
        const paddedElement = document.getElementsByClassName("onload-padd-top")

        if(paddedElement.length>0){
            paddedElement[0].classList.remove("onload-padd-top")
            
        }
    }) 
    // useEffect(() => {
    //     const element = document.getElementById("header");
    //     let text = "Height including padding and border: " + element!.offsetHeight + "px";
    //     // document.getElementById("demo")!.innerHTML = text;
    //     console.log(text, "text")
    // },[])
    // function resizeEventListener() {
    //     var height = document.getElementById('header')!. offsetHeight;
    //     console.log(height, "height");
    //     const element2 = document.getElementsByTagName('header')[0].clientHeight;
    //     console.log(element2, "Header Height");
    // }
    
    // window.addEventListener("resize", resizeEventListener);

    return (
        <>
            <header id='header' >
                <div className="container">
                    <div className="header-hold flex">
                        <div className='pt-60 hamburger-main'>
                            <button className="hamburger" name='Hamburger' aria-label='Open' onClick={showSideNav}>
                                <img src={Hamburger} alt="Hamburger" />
                            </button>
                        </div>
                        <div className='logo-wrap'>
                            <a className="logo logo-height" href="/">
                                <img src={Logo} alt="Lucha Mask" />
                            </a>
                        </div>
                        <div className='pt-60 text-right head-btn'>
                            <div className="btn-group">
                                {address&&<MintButton address={address} correctChain={correctChain}/>}
                                <button className='btn-icon' type='button' onClick={handleOpen}>
                                    <img src={WalletIcon} alt="Wallet Icon" />
                                </button>
                                <WalletButton setAddress={setAddress} setCorrectChain={setCorrectChain} correctChain={correctChain}/>
                            </div>
                        </div>
                        <div className={`sidenav bg-dark-image ${isActive ? "sidenav-show" : ""}`}>
                            <div className="sidenav-hold">
                                <nav id="nav">
                                    <div className="nav-header">
                                        <div className='pt-60'>
                                            <button className="left-arrow" name='LeftArrow' aria-label='Close' onClick={hideSideNav}>
                                                <img src={LeftArrow} alt="Left Arrow" />
                                            </button>
                                        </div>
                                        <div className='logo-wrap'>
                                            <a className="logo" href="/">
                                                <img src={Logo} alt="Lucha Mask" />
                                            </a>
                                        </div>
                                        <div className='pt-60 text-right mint-btn'>
                                            <div className="btn-group">
                                                {address&&<MintButton address={address} correctChain={correctChain}/>}
                                                <WalletButton setAddress={setAddress} setCorrectChain={setCorrectChain} correctChain={correctChain}/>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className='nav-body scroll-bar-none'>
                                    <li>
                                            {/* <a href="#home" onClick={() => { changeActiveButton("home"); }} className={`active ${ activeButton === "home" ? "active" : "" }`}>
                                                Home
                                            </a> */}
                                            <Link activeClass="active" to="home" spy={true} smooth={true} duration={300} onClick={() => { changeActiveButton("home"); }}>
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            {/* <a href="#about" onClick={() => { changeActiveButton("about"); }} className={`${ activeButton === "about" ? "active" : "" }`}>
                                                Join the Project
                                            </a> */}
                                            <Link activeClass="active" to="about" spy={true} smooth={true} duration={300} onClick={() => { changeActiveButton("about"); }}>
                                                Join the Project
                                            </Link>
                                        </li>
                                        <li>
                                            {/* <a href="#howitwork" onClick={() => { changeActiveButton("howitwork"); }} className={`${ activeButton === "howitwork" ? "active" : "" }`}>
                                                How It Works
                                            </a> */}
                                            <Link activeClass="active" to="howitwork" spy={true} smooth={true} duration={300} onClick={() => { changeActiveButton("howitwork") }}>
                                                How It Works
                                            </Link>
                                        </li>
                                        <li>
                                            {/* <a href="#luchadores" onClick={() => { changeActiveButton("luchadores"); }} className={`${ activeButton === "luchadores" ? "active" : "" }`}>
                                                The Luchadores
                                            </a> */}
                                            <Link activeClass="active" to="luchadores" spy={true} smooth={true} duration={300} onClick={() => { changeActiveButton("luchadores"); }}>
                                                The Luchadores
                                            </Link>
                                        </li>
                                        <li>
                                            {/* <a href="#artist" onClick={() => { changeActiveButton("artist"); }} className={`${ activeButton === "artist" ? "active" : "" }`}>
                                                The Artist
                                            </a> */}
                                            <Link activeClass="active" to="artist" spy={true} smooth={true} duration={300} onClick={() => { changeActiveButton("artist"); }}>
                                                The Artist
                                            </Link>
                                        </li>
                                        <li>
                                            {/* <a href="#partner" onClick={() => { changeActiveButton("partner"); }} className={`${ activeButton === "partner" ? "active" : "" }`}>
                                                Partner
                                            </a> */}
                                            <Link activeClass="active" to="partner" spy={true} smooth={true} duration={300} onClick={() => { changeActiveButton("partner"); }}>
                                                Partner
                                            </Link>
                                        </li>
                                        <li>
                                            {/* <a href="#roadmap" onClick={() => { changeActiveButton("roadmap"); }} className={`${ activeButton === "roadmap" ? "active" : "" }`}>
                                                Roadmap
                                            </a> */}
                                            <Link activeClass="active" to="roadmap" spy={true} smooth={true} duration={300} onClick={() => { changeActiveButton("roadmap"); }}>
                                                Roadmap
                                            </Link>
                                        </li>
                                        <li>
                                            {/* <a href="#faq" onClick={() => { changeActiveButton("faq"); }} className={`${ activeButton === "faq" ? "active" : "" }`}>
                                                FAQ
                                            </a> */}
                                            <Link activeClass="active" to="faq" spy={true} smooth={true} duration={300} onClick={() => { changeActiveButton("faq"); }}>
                                                FAQ
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="nav-footer flex">
                                        <li>
                                            <a href='#' target="_blank" className='nav-icon-hold'>
                                                <img src={Instagram} alt="Instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='#' target="_blank" className='nav-icon-hold'>
                                                <img src={Twitter} alt="Twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href='#' target="_blank" className='nav-icon-hold'>
                                                <img src={Discord} alt="Discord" />
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {open? <MobileMintModalBox setOpen ={setOpen} open={open}/>:""}
        </>
    );
}
