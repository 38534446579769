import React, { useState } from "react";
import { MintModalBox } from "../mint-modal-box/MintModalBox";
interface MintButtonProps {
    // 👇️ turn off type checking
    address: string;
    correctChain: boolean;

  }

export const MintButton= ({address,correctChain}:MintButtonProps) => {
    const [open,setOpen] = useState<boolean>(false)
    const handleOpen = () => {
        setOpen(true)
    }
    return (
        <>
            <button className='btn btn-success btn-text' type='button' onClick={handleOpen}>Mint</button>
            {open? <MintModalBox address={address} correctChain={correctChain} setOpen ={setOpen} open={open}/>:""}
        </>
    );
};