import React, { FC, useState } from "react";
import "./scrollToTop.scss";

export const ScrollToTop:FC = () => {
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };
      
      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
      
      window.addEventListener('scroll', toggleVisible);
    return (
        <>
            <button className='scroll-to-top' onClick={scrollToTop} style={{display: visible ? 'flex' : 'none'}}>
                <span className='image-hold'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="17" viewBox="0 0 8 17" fill="none">
                        <path d="M7.7838 4.9486L4.95257 0.966359C4.51598 0.344547 3.48404 0.344547 3.04743 0.966359L0.216199 4.9486C-0.0351948 5.30583 -0.0748614 5.76884 0.136833 6.15255C0.335246 6.53618 0.732161 6.78757 1.16878 6.78757H2.83574V15.1256C2.83574 15.7739 3.35171 16.2899 4 16.2899C4.6483 16.2899 5.16427 15.7739 5.16427 15.1256V6.8008H6.83123C7.26782 6.8008 7.66474 6.56271 7.86317 6.16578C8.07487 5.78216 8.03518 5.30586 7.7838 4.9486Z" fill="white"/>
                    </svg>
                </span>
                <span className="scroll-top-text">Back To Top</span>
            </button>
        </>
    );
};