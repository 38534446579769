import React, { FC } from 'react';
import './partners.scss';
import DoubleArrowBlack from "./../../../assets/images/arrow-black.svg";
import MaskedRepublic from "./../../../assets/images/masked-republic.png";
import Virtua from "./../../../assets/images/virtua.png";

export const Partners:FC = () => {
    return (
        <>
            <section id='partner' className="partners scroll-top-control text-center abc">
                <div className="container">
                    <div className="partner-text-head">
                        <h2>
                            <span className='image-hold'>
                                <img loading="lazy" src={DoubleArrowBlack} alt="Double Arrow" />
                            </span>
                            partners
                        </h2>
                        <p>Lucha Masks is a creative metaverse collaboration between Masked Republic and Virtua.</p>
                    </div>
                    <ul className="partner-cards">
                        <li className='masked-republic'>
                            <a href="http://www.maskedrepublic.com/" target="_blank">
                                <img loading="lazy" className='img-hold' src={MaskedRepublic} alt="Masked Republix" />
                            </a>
                        </li>
                        <li className='virtua'>
                            <a href="https://virtua.com/" target="_blank">
                                <img loading="lazy" className='img-hold' src={Virtua} alt="Virtua" />
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
}