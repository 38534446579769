import React, { FC ,useState } from 'react';
import './home.scss';
import { Banner } from './banner/Banner';
import { JoinMwf } from './join-mwf/JoinMwf';
import { HowItWorks } from './how-it-works/HowItWorks';
import { Luchadores } from './luchadores/Luchadores';
import { Artist } from './artist/Artist';
import { Partners } from './partners/Partners';
import { RoadMap } from './road-map/RoadMap';
import { Faq } from './faq/Faq';
import { CardsModal } from '../../components/cards-modal/CardsModal';
export const Home:FC = () => {
    const [open,setOpen] = useState<boolean>(false)
    const [selectedLucha,setSelectedLucha] = useState<string>("")

    const handleOpen = (lucha) => {
        setSelectedLucha(lucha)
        setOpen(true)
    }
    return (
        
        <>
        
            <Banner/>
            <JoinMwf />
            <div className="bg-dark-pattren">
                <HowItWorks/>
                <Luchadores handleOpen={handleOpen}/>
            </div>
            {open? <CardsModal lucha={selectedLucha} setOpen ={setOpen} open={open}/>:""}
            <Artist/>
            <Partners/>
            <RoadMap/>
            <Faq/>
        </>
    );
}