import { ethers } from "ethers";
import React, { useState, useEffect } from "react";
import { CHAIN_IDS, metaMaskExtensionInstallationLink } from "../../constants/constants";
import "./walletButton.scss";
import { IncorrectChain } from "../incorrect-chain/IncorrectChain";
interface WalletProps {
    // 👇️ turn off type checking
    setAddress: (params: any) => any;
    setCorrectChain: (params: any) => any;
    correctChain:any

}
export const WalletButton = ({setAddress, setCorrectChain,correctChain}: WalletProps) => {
    const [open,setOpen] = useState<boolean>(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const [loading, setLoading] = useState(true)
    const [connected, setConnected] = useState(false)
    const [message, setMessage] = useState('Connect wallet')
    // const [isClicked, setIsClicked] = useState(false)

    // First load
    function hasEthereum() {
        return typeof window !== 'undefined' && typeof window.ethereum !== 'undefined'
    }

    // Request wallet account
    async function requestAccount() {
        await window.ethereum.request({ method: 'eth_requestAccounts' })
    }

    useEffect(function () {
        async function fetchConnectedAccount() {
            if (!hasEthereum()) {
                setMessage('Install MetaMask')
                setLoading(false)
                return
            }
            await setConnectedAccount()
            setLoading(false)
        }
        fetchConnectedAccount()
    }, [])
    useEffect(function () {
        async function listenMMAccount() {
            if (!hasEthereum()) return
            window.ethereum.on('accountsChanged', async function (accounts) {
                if (accounts && accounts[0]) {
                    setMessage(accounts[0])
                    setAddress(accounts[0])
                    setConnected(true)
                } else {
                    setConnected(false)
                    setMessage('Connect Wallet');
                    setAddress("")
                }
            })
            window.ethereum.on("chainChanged", async () => {
                await setConnectedAccount()
            })
        }

        listenMMAccount()
    }, [])
    async function requestConnection() {
        try {
            await requestAccount()
        } catch (error) {
            let errorMessage = 'Unknown Error'
            if (error instanceof Error) errorMessage = error.message
            // we'll proceed, but let's report it
        }
    }

    // Set address of connected wallet
    async function setConnectedAccount() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const address = await signer.getAddress()
            const { chainId } = await provider.getNetwork()

            if (address) {
                setConnected(true)
                setMessage(address);
                setAddress(address)
                if (CHAIN_IDS[process.env.REACT_APP_NETWORK_TYPE] === chainId) {
                      setCorrectChain(true)
                } else {
                      setCorrectChain(false)
                }
            }
        } catch (error) {
            let errorMessage = 'Unknown Error'
            if (error instanceof Error) errorMessage = error.message
            // we'll proceed, but let's report it
            setMessage('Connect Wallet');
            setAddress("")


        }
    }

    // Handle connect wallet click
    // async function handleConnectWallet() {
    //     setLoading(true);
    //     if (hasEthereum() && !connected) {
    //         await requestConnection()
    //         await setConnectedAccount()
            
    //     }if(hasEthereum()&& connected){
    //         setIsClicked(true)
    //     } else if(!hasEthereum()) {
    //         window.open(metaMaskExtensionInstallationLink, "_blank")
    //     }


    //     setLoading(false);
    // }
    async function handleConnectWallet() {
        setLoading(true);
        if (hasEthereum()) {
            await requestConnection()
            await setConnectedAccount()
        }
         else  {
            window.open(metaMaskExtensionInstallationLink, "_blank")
        }


        setLoading(false);
    }

    return (
        <>
            <div className="relative btn-text">
                <button className={`btn ${connected?"btn-light":"btn-success"}`} type='button' onClick={handleConnectWallet}>{connected ? `${message.substring(0, 4)}...${message.substring(
                    message.length - 4
                )}` : message}</button>
                {/* {connected&&isClicked&& <div className="absolute tooltip tooltip-bottom">
                    <span className="arrow-up">
                        <img src={TooltipArrowUp} alt="Arrow Up" />
                    </span>
                    <button className="fw-700" onClick={()=>{setConnected(false);setAddress("");setMessage("Connect Wallet"); setIsClicked(false)}}>Disconnect Wallet</button>
                </div>}  */}
            </div>
            {connected&& !correctChain? <IncorrectChain setOpen ={setOpen} open={open}/>:""}
        </>
    );
};