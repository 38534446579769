import React, { FC } from 'react';
import './footer.scss';
import FooterLogo from "./../../assets/images/logo.png";
import Instagram from "./../../assets/images/instagram-dark.svg";
import Twitter from "./../../assets/images/twitter-dark.svg";
import Discord from "./../../assets/images/discord-dark.svg";

export const Footer:FC = () => {
    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="top-footer">
                        <figure className="logo">
                            <img loading="lazy" src={FooterLogo} alt="Lucha Mask" />
                        </figure>
                        <div className="text-hold">
                            <div className="footer-nav">
                                <ul className="nav-hold">
                                    <li>
                                        <a href="#home">Home</a>
                                    </li>
                                    <li>
                                        <a href="#about">Join The Project</a>
                                    </li>
                                    <li>
                                        <a href="#howitwork">How It Works</a>
                                    </li>
                                    <li>
                                        <a href="#luchadores">The Luchadores</a>
                                    </li>
                                    <li>
                                        <a href="#artist">The Artist</a>
                                    </li>
                                    <li>
                                        <a href="#partner">Partner</a>
                                    </li>
                                    <li>
                                        <a href="#roadmap">Roadmap</a>
                                    </li>
                                    <li>
                                        <a href="#faq">FAQ</a>
                                    </li>
                                </ul>
                                <ul className="social-icon">
                                    <li>
                                        <a href="#" target="_blank" className='icon-hold'>
                                            <img src={Instagram} alt="Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank" className='icon-hold'>
                                            <img src={Twitter} alt="Twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank" className='icon-hold'>
                                            <img src={Discord} alt="Discord" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="copy-wright">
                        <p>@TheLuchaMask 2022, All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        </>
    );
}