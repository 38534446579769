import React, { FC, useState } from 'react';
import './roadMap.scss';
import DoubleArrowWhite from "./../../../assets/images/double-arrow.svg";
import MetaverseFight from "./../../../assets/images/metaverse-fight.png";
import LuchadoresNFT from "./../../../assets/images/roadmap-luchadores.png";
import LuchadoresMetaverse from "./../../../assets/images/roadmap-lucha-meta.png";
import RoadmapGetNFT from "./../../../assets/images/roadmap-get-nft.png";
import RoadmapSlider1 from "./../../../assets/images/rodmap-one.png";
import RoadmapSlider2 from "./../../../assets/images/roadmap-two.png";
import RoadmapSlider3 from "./../../../assets/images/roadmap-three.png";
import RoadmapSlider4 from "./../../../assets/images/roadmap-four.png";
import MobileRoadmapLine1 from "./../../../assets/images/mobile-roadmap-line1.png";
import MobileRoadmapLine2 from "./../../../assets/images/mobile-roadmap-line2.png";
import MobileRoadmapLine3 from "./../../../assets/images/mobile-roadmap-line3.png";
import MobileRoadmapLine4 from "./../../../assets/images/mobile-roadmap-line4.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const RoadMap:FC = () => {
    const [sliderRef, setSliderRef] = useState<any>(null)
    const settings = {
        infinite: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        addaptiveHeight: true
    }
    return (
        <>
            <section id='roadmap' className="roadmap scroll-top-control abc">
                <div className="roadmap-text-head">
                    <h2>
                        <span className='image-hold'>
                            <img loading="lazy" src={DoubleArrowWhite} alt="Double Arrow" />
                        </span>
                        Road Map
                    </h2>
                </div>
                <Slider {...settings} ref={setSliderRef} >
                    <div className="roadmap-slider">
                        <div className="container">
                            <div className="roadmap-slider-hold">
                                <div className="text-hold">
                                    <span className='date-time fw-700'>V1</span>
                                    <h3>Lucha Masks </h3>
                                    <p>10,000 generative NFT luchador wrestling masks, bringing the spirit and culture of lucha libre onto the blockchain!</p>
                                    <ul className='slider-control'>
                                        <li>
                                            <span className="left-arrow icon-hold" onClick={sliderRef?.slickPrev}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                                    <path d="M26.015 14.5322H0.5V17.5322H26.015V22.0322L32 16.0322L26.015 10.0322V14.5322Z" fill="#353535"/>
                                                </svg>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='slider-digits'>01/04</span>
                                        </li>
                                        <li>
                                            <span className="right-arrow icon-hold" onClick={sliderRef?.slickNext}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                                    <path d="M26.015 14.5322H0.5V17.5322H26.015V22.0322L32 16.0322L26.015 10.0322V14.5322Z" fill="#353535"/>
                                                </svg>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <figure className="image-hold">
                                    <img loading="lazy" src={MetaverseFight} alt="Metaverse Fight" />
                                </figure>
                            </div>
                        </div>
                        <picture className='slider-image roadmap-slide-1'>
                            <source srcSet={RoadmapSlider1} media="(min-width: 768px)"/>
                            <img src={MobileRoadmapLine1} alt="Road Map 1" />
                        </picture>
                    </div>
                    <div className="roadmap-slider">
                        <div className="container">
                            <div className="roadmap-slider-hold">
                                <div className="text-hold">
                                    <span className='date-time fw-700'>V2</span>
                                    <h3>New Gear!</h3>
                                    <p>Grab more epic and unique wrestling gear, including boots, weapons, and body styles. Create your own unique wrestler, and introduce them to the Virtua metaverse ecosystem!</p>
                                    <ul className='slider-control'>
                                        <li>
                                            <span className="left-arrow icon-hold" onClick={sliderRef?.slickPrev}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                                    <path d="M26.015 14.5322H0.5V17.5322H26.015V22.0322L32 16.0322L26.015 10.0322V14.5322Z" fill="#353535"/>
                                                </svg>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='slider-digits'>02/04</span>
                                        </li>
                                        <li>
                                            <span className="right-arrow icon-hold" onClick={sliderRef?.slickNext}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                                    <path d="M26.015 14.5322H0.5V17.5322H26.015V22.0322L32 16.0322L26.015 10.0322V14.5322Z" fill="#353535"/>
                                                </svg>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchadoresNFT} alt="Metaverse Fight" />
                                </figure>
                            </div>
                        </div>
                        <picture className='slider-image roadmap-slide-2'>
                            <source srcSet={RoadmapSlider2} media="(min-width: 768px)"/>
                            <img src={MobileRoadmapLine2} alt="Road Map 2" />
                        </picture>
                    </div>
                    <div className="roadmap-slider">
                        <div className="container">
                            <div className="roadmap-slider-hold">
                                <div className="text-hold">
                                    <span className='date-time fw-700'>V3</span>
                                    <h3>Enter the MWF</h3>
                                    <p>Enter the world of wrestling like never before! The MWF is being built by an incredible team of metaverse and wrestling insiders and superstars. Lucha Mask holders get early access!</p>
                                    <ul className='slider-control'>
                                        <li>
                                            <span className="left-arrow icon-hold" onClick={sliderRef?.slickPrev}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                                    <path d="M26.015 14.5322H0.5V17.5322H26.015V22.0322L32 16.0322L26.015 10.0322V14.5322Z" fill="#353535"/>
                                                </svg>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='slider-digits'>03/04</span>
                                        </li>
                                        <li>
                                            <span className="right-arrow icon-hold" onClick={sliderRef?.slickNext}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                                    <path d="M26.015 14.5322H0.5V17.5322H26.015V22.0322L32 16.0322L26.015 10.0322V14.5322Z" fill="#353535"/>
                                                </svg>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchadoresMetaverse} alt="Metaverse Fight" />
                                </figure>
                            </div>
                        </div>
                        <picture className='slider-image roadmap-slide-3'>
                            <source srcSet={RoadmapSlider3} media="(min-width: 768px)"/>
                            <img src={MobileRoadmapLine3} alt="Road Map 3" />
                        </picture>
                    </div>
                    <div className="roadmap-slider">
                        <div className="container">
                            <div className="roadmap-slider-hold">
                                <div className="text-hold">
                                    <span className='date-time fw-700'>V4</span>
                                    <h3>P2E Games</h3>
                                    <p>Turn your passion for wrestling into a source of value and income, just by playing games and having fun!</p>
                                    <ul className='slider-control'>
                                        <li>
                                            <span className="left-arrow icon-hold" onClick={sliderRef?.slickPrev}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                                    <path d="M26.015 14.5322H0.5V17.5322H26.015V22.0322L32 16.0322L26.015 10.0322V14.5322Z" fill="#353535"/>
                                                </svg>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='slider-digits'>04/04</span>
                                        </li>
                                        <li>
                                            <span className="right-arrow icon-hold" onClick={sliderRef?.slickNext}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                                    <path d="M26.015 14.5322H0.5V17.5322H26.015V22.0322L32 16.0322L26.015 10.0322V14.5322Z" fill="#353535"/>
                                                </svg>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <figure className="image-hold">
                                    <img loading="lazy" src={RoadmapGetNFT} alt="Metaverse Fight" />
                                </figure>
                            </div>
                        </div>
                        <picture className='slider-image roadmap-slide-4'>
                            <source srcSet={RoadmapSlider4} media="(min-width: 768px)"/>
                            <img src={MobileRoadmapLine4} alt="Road Map 4" />
                        </picture>
                    </div>
                </Slider>
                
            </section>
        </>
    );
}