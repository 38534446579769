import React, { FC } from "react";
import "./mobileMintModal.scss";
import CrossIcon from "./../../assets/images/cross-icon.svg";
import MobileMintIcon from "./../../assets/images/mobile-mint-icon.png";

interface IMyProps {
    open: boolean,
    setOpen:any
}
export const MobileMintModalBox:FC<IMyProps> = ({open,setOpen}:any) => {
    return (
        <>
            <div className="mobile-mint-modal modal modal-overlay fixed">
                <div className="modal-sm">
                    <div className="modal-content">
                    <div className="modal-header">
                            <h5>Important Notice</h5>
                            <button type="button" aria-label="close" onClick={()=>setOpen(false)}>
                                <img loading="lazy" className="image-hold" src={CrossIcon} alt="Cross" />
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <img loading="lazy" src={MobileMintIcon} alt="Mobile Mint Modal" />
                            <h5>Welcome to the MWF!</h5>
                            <p>Please use the desktop version of our site to mint your NFTs.</p>
                            <p>We’ll see you in the metaverse!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};