import React, { FC, useEffect } from 'react';
import { Header } from './components/header/Header';
import { Home } from './pages/home/Home';
import { Footer } from './components/footer/Footer';
import { ScrollToTop } from './components/scroll-to-top/ScrollToTop';

export const App:FC = () => {

//   let section,menu
//   useEffect(()=>{  
//      section = document.querySelectorAll("section");
//      menu = document.querySelectorAll("header nav a");

//   },[])


// window.onscroll = () => {
//   section.forEach((i) => {
//     let top = window.scrollY;
//     let offset = i.offsetTop - 126;
//     let height = i.offsetHeight;
//     let id = i.getAttribute("id");

//     if (top >= offset && top < offset + height) {
//       menu.forEach((link) => {
//         link.classList.remove("active");
//         const matchedQuery = document.querySelector("header nav a[href*=" + id + "]");
//         matchedQuery?matchedQuery.classList.add("active"):console.log("no matched query");
//       });
//     }
//   });
// };
// To check the scroll position on page load
  return (
    <div id="wrapper">
        <Header/>
        <main id='main'>
          <Home/>
        </main>
        <Footer/>
        <ScrollToTop/>
    </div>
  );
}
