import React, { FC ,useState,useEffect} from "react";
import "./mintModalBox.scss";
import CrossIcon from "./../../assets/images/cross-icon.svg";
import Wallet from "./../../assets/images/wallet.png";
import NftTokens from "./../../assets/images/nft-tokens.png";
import MintLoader from "./../../assets/images/mint-loader.gif";
import TransactionDetail from "./../../assets/images/transaction-link.svg";
import { ethers } from "ethers";
import { metaMaskExtensionInstallationLink } from "../../constants/constants";
import Mint from "../../abi/Mint.json"
import Token from "../../abi/Token.json"


interface IMyProps {
    open: boolean,
    setOpen:any
    address: string;
    correctChain: boolean;
}
export const MintModalBox:FC<IMyProps> = ({open,setOpen,address,correctChain}:any) => {
    // const provider = new ethers.providers.Web3Provider(window.ethereum)
    
    const [ethBalance,setEthBalance]=useState(-1) 
    const [mintQuantity,setMintQuantity]=useState(1)
    const [totalNFTs, setTotalNFTs] = useState(0);
    const [transaction, setTransaction] = useState(0);

    const [mintedNFTs, setMintedNFTs] = useState(0);
  const [error, setError] = useState("");
  const [mintPrice, setMintPrice] = useState<any>(0);

  const [mintingLimit, setMintingLimit] = useState(3);
  const [remainingMintingLimit, setRemainingMintingLimit] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState<any>(null);
  const [contract, setContract] = useState<any>(null);
  const [percentage, setPercentage] = useState<any>(0);
  
  const InstallMetamaskRedirect = () => {
    setTimeout(
      () => window.open(metaMaskExtensionInstallationLink, "_blank"),
      1000
    );
  };

  const fetchContract = async () => {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(prov);
      const signer = prov.getSigner();
      const con = new ethers.Contract(
        process.env.REACT_APP_MINT_CONTRACT_ADDRESS!,
        Mint,
        signer
      );
      console.log(process.env.REACT_APP_MINT_CONTRACT_ADDRESS,"process.env.REACT_APP_MINT_CONTRACT_ADDRESS")
      setContract(con);

      const mintvalue = await con.mintPrice()
      setMintPrice(ethers.utils.formatEther(mintvalue))
      console.log("Mint value", ethers.utils.formatEther(mintvalue));
      console.log(address,"user address")
      await prov.getBalance(address).then((balance) => {
        // convert a currency unit from wei to ether
        const balanceInEth = ethers.utils.formatEther(balance)
        console.log(`balance: ${balanceInEth} ETH`);
        setEthBalance(parseFloat(parseFloat(balanceInEth).toFixed(4)))
        
       });
       fetchMintingdata();
      window.ethereum.on("chainChanged", () => fetchContract());
      

      // window.ethereum.on("ac", () => fetchContract());

    } else {
      InstallMetamaskRedirect();
    }
  };

  useEffect(() => {
    fetchContract();
  }, []);

  const fetchMintingdata = async () => {
    if (provider) {
      const totalnft  = await contract.totalSupply();
      const totalmintednft = await contract.tokenCount();
      setMintedNFTs(parseInt(totalmintednft));
      setTotalNFTs(parseInt(totalnft));
      let formula = ((totalmintednft)/(totalnft))*100;
      if(parseInt(totalmintednft)===parseInt(totalnft)){
        setError("All NFTs have been minted")
      }
      
    setPercentage(formula.toFixed(2));
    
    }
  };
  window.ethereum.on("chainChanged", () => fetchContract());

  useEffect(() => {
    fetchMintingdata();
    getAddressLimit();
  }, [address, provider]);

  window.ethereum.on('accountsChanged', () => {setOpen(false);})

  
  const getAddressLimit = async () => {
    if (provider) {

      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(
        process.env.REACT_APP_MINT_TOKEN_ADDRESS!,
        Token,
        signer
      );
        let mintinglimitTemp = 3
        const whitelistedChecking = await contract.whiteList(address);
        let tvkBalance = await tokenContract.balanceOf(address);
        var decimals = await tokenContract.decimals();
        tvkBalance = (tvkBalance / (10 ** decimals));
        const nftsmintcount = await contract.minters(address)
        if(tvkBalance  >= 500){
          mintinglimitTemp = 5
          
        }
        else if(whitelistedChecking){
          mintinglimitTemp =5


        }else{
          mintinglimitTemp =3

        }

        setMintingLimit(mintinglimitTemp)
        setRemainingMintingLimit(mintinglimitTemp - nftsmintcount.toNumber()<0?0:mintinglimitTemp - nftsmintcount.toNumber())
        if (((mintinglimitTemp - nftsmintcount.toNumber() )=== 0 || mintQuantity > (mintinglimitTemp - nftsmintcount.toNumber())) && !transaction) {
            setError(`Minting limit reached`);
          }
    }
  };

  async function mintNFTs() {
    setTransaction(0)
    setError("")
    try{
      if(mintQuantity >0 && mintQuantity<=remainingMintingLimit){
        let price = mintQuantity * mintPrice;
        let s_price = price.toString()
        setIsLoading(true)
        try {
          let tx =  await contract.mintMask(mintQuantity,{ value: ethers.utils.parseUnits(s_price, "ether") });          
          await tx.wait()
          setIsLoading(false)

          setTransaction(tx.hash);

        } catch (err) {
          console.log(err)
          setError("User rejected transction")
          setIsLoading(false)
        }
      

      // fetchMintingdata();
      // getAddressLimit();
      }
     
    }catch (err:any) {
      
   
    }
  };
  
  useEffect(()=>{if((mintQuantity*mintPrice)>ethBalance && ethBalance > -1){setError("You do not have enough ETH to complete the minting process")}else{setError("")}},[mintQuantity,ethBalance,mintPrice])
    return (
        <>
            <div className="mint-modal modal modal-overlay fixed">
                <div className="modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>Mint luchamask</h5>
                            <button type="button" aria-label="close" onClick={()=>setOpen(false)}>
                                <img loading="lazy" className="image-hold" src={CrossIcon} alt="Cross" />
                            </button>
                        </div>
                        <div className="modal-body text-center">
                          {/* When Success message Show then the class show on div none */}
                            {transaction?<div className="success-message">
                              <h3>Congratulations<br/>Your mint has been successful!</h3>
                              <a href={`https://${process.env.REACT_APP_NETWORK_TYPE==="TESTNET"?"rinkeby":""}.etherscan.io/tx/${transaction}`}className="trans-detail fw-500" target="_blank">
                              See transaction details
                              <img loading="lazy" className="image-hold" src={TransactionDetail} alt="Transaction Detail" />
                              </a>
                            </div>:<div className="">
                              {/* {transaction>0&&<h3 className="text-success">congratulations you have <br/> successfully Minted</h3>}
                              {error&&<h3 className="text-danger">{error}<br/> please try again</h3>} */}
                              {/* <h2 className="inline-block">{mintedNFTs}/{totalNFTs}</h2> */}
                              {/* disable mint quantity when minting is not available */}
                              <div className="progress-wrapper">
                                  <span className="progress-text flex">
                                      <small className="fw-500">Total Minted Items</small>
                                      <span className="ml-auto">{mintedNFTs}/{totalNFTs}</span>
                                  </span>
                                  <section className="flex">
                                      <span  style={{width:`${percentage}%`}} className="progress-thumb" />
                                      <small className="percentage-text fw-700">{percentage}%</small>
                                  </section>
                              </div>
                              <div className={`mint-quantity ${remainingMintingLimit===0?"disabled":""}`}>
                                  <div className="mint-quantity-text">
                                      <h5>Mint Quantity</h5>
                                      <span className="inline-block">Max Mint Quantity {remainingMintingLimit}</span>
                                  </div>
                                  <div className="mint-quantity-hold">
                                      <span className="less-quantity inline-block" onClick={()=>{if(mintQuantity>1) {setMintQuantity(mintQuantity-1)}}}>-</span>
                                      <span className="numb-quantity fw-700 inline-block">{mintQuantity}</span>
                                      <span className="add-quantity inline-block" onClick={()=>{if(mintQuantity<remainingMintingLimit) {setMintQuantity(mintQuantity+1);}}}>+</span>
                                  </div>
                              </div>
                              <ul className="mint-detail">
                                  <li>
                                      <span className="fw-500">Mint</span>
                                      <span className="fw-700">
                                          <small className="fw-500">Price: </small>{mintPrice} ETH
                                      </span>
                                  </li>
                                  <li>
                                      <span className="fw-500">ETH Balance</span>
                                      <span className="fw-700">
                                          {ethBalance>=0 && correctChain?`${ethBalance} ETH`:"-"}
                                      </span>
                                  </li>
                              </ul>
                              {/* <strong className="remain-wallet inline-block">0.05 ETH / NFT <br/> Wallet Balance 0.151 ETH</strong> */}
                              {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using</p> */}
                              {/* <ul className='timer text-center'>
                                  <li>
                                      <span>14</span>
                                      <small>Days</small>
                                  </li>
                                  <li>
                                      <span>:</span>
                                  </li>
                                  <li>
                                      <span>06</span>
                                      <small>Hours</small>
                                  </li>
                                  <li>
                                      <span>:</span>
                                  </li>
                                  <li>
                                      <span>22</span>
                                      <small>Minutes</small>
                                  </li>
                                  <li>
                                      <span>:</span>
                                  </li>
                                  <li>
                                      <span>03</span>
                                      <small>Minutes</small>
                                  </li>
                              </ul> */}
                              {/* {!correctChain && address&&<p className="text-danger italic fw-700">{`Incorrect chain - Please switch to the ${NETWORKS[process.env.REACT_APP_NETWORK_TYPE]} network`}</p>}
                              {!address && <p className="fs-16 fw-700 italic">Please Connect Wallet in order to NFT</p>} */}
                              
                              {/* These error message shown when we need these */}
                              {correctChain&& error&&<p className="error-message text-danger italic fw-700">{error}</p>}
                            </div>}
                            <button type="button" onClick={transaction?()=>setOpen(false):mintNFTs} className={`btn btn-dark relative`} disabled={(mintQuantity*mintPrice>ethBalance || remainingMintingLimit===0 || isLoading )&& !transaction }>{(transaction)?"Close":"Mint Mask"}
                            {/* active the class active */}
                                {isLoading&&<span className="mint-loader active">
                                    <img loading="lazy" src={MintLoader} alt="Mint Loader" />
                                </span>}
                            </button>
                        </div>
                        {transaction?"":<div className="modal-footer text-left">
                            <ul>
                                <li>
                                    <figure className="image-hold">
                                        <img loading="lazy" src={Wallet} alt="Wallet" />
                                    </figure>
                                    You can mint up to 3<br/>LuchaMask NFTs per wallet

                                </li>
                                <li>
                                    <figure className="image-hold">
                                        <img loading="lazy" src={NftTokens} alt="NFT Tokens" />
                                    </figure>
                                    You can mint up to 5 LuchaMasks per <br/>wallet containing at least 500 TVK
                                </li>
                            </ul>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
};