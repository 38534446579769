import React, { FC } from "react";
import "./incorrectChain.scss";

interface IMyProps {
    open: boolean,
    setOpen:any
}
export const IncorrectChain:FC<IMyProps> = ({open,setOpen}:any) => {
    return (
        <>
            <div className="incorrect-chain modal modal-overlay fixed">
                <div className="modal-lg">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <strong className="fw-700 italic">Incorrect chain - Please switch to the Rinkeby network</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};