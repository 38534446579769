import React, { FC } from "react";
import "./cardsModal.scss";
import LuchaJuventud from "./../../assets/images/lucha-juventud.png";
import LuchaSolar from "./../../assets/images/lucha-solar.png";
import LuchaTaurus from "./../../assets/images/lucha-taurus.png";
import LuchaBaronessa from "./../../assets/images/lucha-baronessa.png";
import LuchaAeroboy from "./../../assets/images/lucha-aeroboy.png";
import LuchaDragonLee from "./../../assets/images/lucha-dragonlee.png";
import LuchaPsychosis from "./../../assets/images/lucha-psychosis.png";
import LuchaPsychoClown from "./../../assets/images/lucha-psychoclown.png";
import LuchaPirataMorgan from "./../../assets/images/lucha-pirata.png";
import LuchaKonnan from "./../../assets/images/lucha-konnan.png";
import LuchaUltimoDragon from "./../../assets/images/lucha-ultimo.png";
import LuchaAstroJr from "./../../assets/images/lucha-astrojr.png";
import LuchaRush from "./../../assets/images/lucha-rush.png";
import LuchaMatematico from "./../../assets/images/lucha-matematico.png";
import LuchaLaredo from "./../../assets/images/lucha-laredo.png";
import LuchaFelino from "./../../assets/images/lucha-felino.png";
import LuchaTormenta from "./../../assets/images/lucha-tormenta.png";
import LuchaDorado from "./../../assets/images/lucha-dorado.png";
import Instagram from "./../../assets/images/instagram.svg";
import Twitter from "./../../assets/images/twitter.svg";

interface IMyProps {
    open: boolean,
    setOpen:any,
    lucha:string
}
export const CardsModal:FC<IMyProps> = ({open,setOpen,lucha}:any) => {
    const luchaCollection = {
        solar:{img:LuchaSolar,name:"Solar",info:"One of the most iconic luchadores in history, Solar was part of the “Los Cadetes del Espacio” trio who helped usher in both the high flying style and trios matches as staples of the sport."},
        taurus:{img:LuchaTaurus,name:"Black Taurus",info:"The Black Beast has proven himself a powerful force around the globe. Whether its winning championships or locking horn with former UFC Heavyweight Champ Cain Velasquez, Taurus’ star is on the rise."},
        guerrera:{img:LuchaJuventud,name:"Juventud Guerrera",info:"Son of the legendary Fuerza Guerrera, Juvy eclipsed his father on a global scale as a top lucha star in WCW and WWE. One of the most innovative offenses in the game."},
        baronessa:{img:LuchaBaronessa,name:"Baronessa",info:"The first ever Baja California Women’s Champion and the top luchadora in region over the past five years, Baronessa has started to break out both nationally in Mexico and internationally in the U.S."},
        Aeroboy:{img:LuchaAeroboy,name:"Aeroboy",info:"Debuting at only 13 years old, Aeroboy has nearly two decades under his mask. While he’s an extremely talented high flyer, the daredevil is perhaps best known globally for his deathmatch wrestling."},
        lee:{img:LuchaDragonLee,name:"Dragon Lee",info:"One of the biggest stars of the current era, Dragon Lee held multiple titles in CMLL before winning Japan’s NJPW Jr. Heavyweight title and U.S. based Ring of Honor’s World TV and Tag Team titles."},
        psychosis:{img:LuchaPsychosis,name:"Psychosis",info:"In 1994, Psychosis and Rey Misterio Jr. changed the game of pro wrestling introducing the super fast-paced lucha libre style to Japan and then the U.S. via ECW. Titles in Mexico and the U.S. followed."},
        clown:{img:LuchaPsychoClown,name:"Psycho Clown",info:"He looks like a creature of nightmares but he is the most popular luchador in Mexico today! From the legendary Brazo family, beyond belts his biggest win was taking the mask of Dr. Wagner Jr!"},
        morgan:{img:LuchaPirataMorgan,name:"Pirata Morgan",info:"One of the biggest 90s stars, known for bloody brawls and scalping his opponents – via winning more than a dozen hair matches. Legit eye patch wearing luchador has held more than a dozen titles too."},
        konnan:{img:LuchaKonnan,name:"Konnan",info:"One of the most influential luchadores in history, not only a top draw in Mexico but he shaped the future of the business pushing luchadores to ECW and WCW. Title reigns include CMLL, AAA, WCW, TNA."},
        dragon:{img:LuchaUltimoDragon,name:"Ultimo Dragon",info:"Arguably the greatest Japanese luchador ever, he is best known outside of lucha for holding the J Crown – 10 different junior heavyweight championships at the same time – a record held to this day!"},
        astro:{img:LuchaAstroJr,name:"Super Astro Jr",info:"Son of the legendary Super Astro, Jr. wowed in CMLL winning Lucha Central’s Rookie of the Year award in 2018. Now Astro Jr. and Solar Jr. will team as “Los Cadetes del Futuro” in the ring and out."},
        rush:{img:LuchaRush,name:"Rush",info:"Pure rudo is one of the biggest draws of the decade in Mexico. “El Toro Blanco” Rush won multiple titles in CMLL then joined ROH and won their World Championship. Brother of Dragon Lee & Dralistico."},
        matematico:{img:LuchaMatematico,name:"El Matemático",info:"Debuting in 1968, El Matemático is a lucha icon. One of four luchador brothers including Hurácan Ramirez and father to four of his own. The man with numbers on his mask has won 11 from others!"},
        kid:{img:LuchaLaredo,name:"Laredo Kid",info:"While he wow’d in his first AAA run from 2005-2011, since 2018 he’s held singles and trios gold and won two major tourneys. One of the best modern era high flyers, he’s proving it now in Impact too."},
        felino:{img:LuchaFelino,name:"Felino Jr.",info:"Son of the legendary Felino and member of the Casas lucha dynasty, Felino Jr. has a lot to live up to – but he’s well on his way as one half of the current Mexico National Tag Team Champions in CMLL."},
        tormenta:{img:LuchaTormenta,name:"Chik Tormenta",info:"Popular independent luchadora made AAA appearances on and off over the last few years until joining the roster full time and teaming with Arez to win the Mixed Tag Team Titles. Bright future ahead!"},
        dorado:{img:LuchaDorado,name:"Lince Dorado",info:"A rare masked Puerto Rican luchador he was a top indie standout before being drafted to WWE. Feeling he hit a glass ceiling he asked for his release an now 2022 is poised to be the Year of the Lynx."},
    }
    return (
        <>
            <div className="cards-modal modal modal-overlay fixed">
                <div className="modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5></h5>
                            <button type="button" aria-label="close" onClick={()=>setOpen(false)}>
                               <span className="cross-circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M15.1943 2.41714C15.6393 1.97216 15.6393 1.2507 15.1943 0.805714C14.7493 0.36073 14.0278 0.360731 13.5829 0.805714L8 6.38857L2.41714 0.805715C1.97216 0.360732 1.2507 0.36073 0.805714 0.805714C0.36073 1.2507 0.360731 1.97216 0.805714 2.41714L6.38857 8L0.805715 13.5829C0.360732 14.0278 0.36073 14.7493 0.805714 15.1943C1.2507 15.6393 1.97216 15.6393 2.41714 15.1943L8 9.61143L13.5829 15.1943C14.0278 15.6393 14.7493 15.6393 15.1943 15.1943C15.6393 14.7493 15.6393 14.0278 15.1943 13.5829L9.61143 8L15.1943 2.41714Z" fill="#000"/>
                                    </svg>
                               </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <figure className="card-modal-img">
                                <img loading="lazy" src={luchaCollection[lucha].img} alt={luchaCollection[lucha].name} />
                            </figure>
                            <div className="text-hold">
                                <strong className="fw-700 inline-block">{luchaCollection[lucha].name}</strong>
                                <p>{luchaCollection[lucha].info}</p>
                                <ul className="social-icon flex">
                                    <li>
                                        <a href='#' target="_blank" className='nav-icon-hold'>
                                            <img src={Instagram} alt="Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' target="_blank" className='nav-icon-hold'>
                                            <img src={Twitter} alt="Twitter" />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href='#' target="_blank" className='nav-icon-hold'>
                                            <img src={Discord} alt="Discord" />
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};