import React, { FC, useState } from 'react';
import './joinMwf.scss';
import JoinProject from "./../../../assets/images/join-project.png";
import DoubleArrow from "./../../../assets/images/double-arrow.svg";

export const JoinMwf:FC = () => {
    const [isActive, setIsActive] = useState(false);
    const collapsetoggle = event => {
        setIsActive(current => !current);
    };
    return (
        <>
             <section id='about' className='join-mwf abc'>
                 <div className="container">
                 <div className='join-project scroll-top-control'>
                    <figure className='join-project-img image-hold'>
                        <img src={JoinProject} alt="Join Project" />
                    </figure>
                    <div className={isActive ? 'join-project-text collapse-open' : 'join-project-text'}>
                        <h2>
                            <span className='image-hold'>
                                <img loading="lazy" src={DoubleArrow} alt="Double Arrow" />
                            </span>
                            JOIN THE Project
                        </h2>
                        <div className="text-hold">
                            <p>Lucha Mask owners automatically gain exclusive access to the Metaverse Wrestling Federation (MWF), a new professional wrestling association born in the metaverse! Perks include:</p>
                            <ul className="mwf-list">
                                <li>
                                    <span className='image-hold'>
                                        <img loading="lazy" src={DoubleArrow} alt="Double Arrow" />
                                    </span>
                                    Access to exclusive physical-world events and festivals – immerse yourself in the culture and legacy of lucha libre!
                                </li>
                                <li>
                                    <span className='image-hold'>
                                        <img loading="lazy" src={DoubleArrow} alt="Double Arrow" />
                                    </span>
                                    Discounts for a fully authentic and professional-grade version of your Lucha Mask, created by Oscar “Shock” García and his team (with a QR code inside that ties the physical mask to your NFT). Own your own piece of wrestling history!
                                </li>
                                <li>
                                    <span className='image-hold'>
                                        <img loading="lazy" src={DoubleArrow} alt="Double Arrow" />
                                    </span>
                                    Live-streamed luchador meet-and-greet sessions. Hang out live with your favourite legends, and discuss your passion with the latest up-and-coming stars!
                                </li>
                                <li>
                                    <span className='image-hold'>
                                        <img loading="lazy" src={DoubleArrow} alt="Double Arrow" />
                                    </span>
                                    Exclusive drops and merch. When a new high-flying collection enters the ring, we’ll tag you in!
                                </li>
                                <li>
                                    <span className='image-hold'>
                                        <img loading="lazy" src={DoubleArrow} alt="Double Arrow" />
                                    </span>
                                    Access to the V2 stage of the Lucha Masks and MWF. We’re just getting started…
                                </li>
                            </ul>
                            <button className='collapse-toggle' onClick={collapsetoggle}>{isActive ? 'Show Less' : 'Show More'}</button>
                        </div>
                        <ul className="social-icon">
                            <li>
                                <button className='btn btn-border-success' type='button'>Join Discord</button>
                            </li>
                            <li>
                                <a href="#" target="_blank" className='icon-wrap'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26" fill="#fff">
                                    <path d="M29.25 1.75C28.053 2.59434 26.7276 3.24013 25.325 3.6625C24.5722 2.79688 23.5717 2.18336 22.4588 1.9049C21.3459 1.62645 20.1744 1.69649 19.1026 2.10556C18.0308 2.51464 17.1106 3.243 16.4662 4.19214C15.8219 5.14129 15.4846 6.26542 15.5 7.4125V8.6625C13.3033 8.71946 11.1266 8.23226 9.16376 7.2443C7.20093 6.25635 5.5129 4.79829 4.25 3C4.25 3 -0.75 14.25 10.5 19.25C7.92566 20.9975 4.85895 21.8737 1.75 21.75C13 28 26.75 21.75 26.75 7.375C26.7489 7.02681 26.7154 6.67949 26.65 6.3375C27.9258 5.07936 28.826 3.49089 29.25 1.75Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                 </div>
            </section>  
        </>
    );
}