import React, { FC } from 'react';
import './howItWorks.scss';
import DoubleArrowWhite from "./../../../assets/images/arrow-white.png";

export const HowItWorks:FC = () => {
    return (
        <>
            <section id='howitwork' className="how-it-works bg-dark-gradient scroll-top-control abc">
                <div className="container">
                    <div className="how-works-hold text-center">
                        <div className="text-hold">
                            <h2>
                                <span className='image-hold'>
                                    <img loading="lazy" src={DoubleArrowWhite} alt="Double Arrow" />
                                </span>
                                how it works?
                            </h2>
                            <p>Simply buy (mint) your own Lucha Mask to unlock your genesis pass to the Metaverse Wrestling Federation, and access the perks listed above!</p>
                            <p>The generative Lucha Masks NFT collection incorporates a range of original masks designed by Oscar “Shock” García, as well as the officially licensed masks of more than a dozen world-class lucha libre superstars including Konnan, Dragon Lee, Rush, Baronessa, Chik Tormenta, and Lince Dorado.</p>
                            <p>A percentage of all Lucha Mask sales will be donated to two lucha libre charities (to be announced soon).</p>
                        </div>
                        <p>Keep scrolling for more information about the future of the Lucha Masks and MWF!</p>
                    </div>
                </div>
            </section>
        </>
    );
}