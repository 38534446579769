import React, { FC } from 'react';
import './artist.scss';
import DoubleArrowBlack from "./../../../assets/images/arrow-black.svg";
import ArtistImage from "./../../../assets/images/artist.png";
import InstagramDark from "./../../../assets/images/instagram-dark.svg";
import TwitterDark from "./../../../assets/images/twitter-dark.svg";

export const Artist:FC = () => {
    return (
        <>
            <section id='artist' className="artist scroll-top-control abc">
                <div className="container">
                    <div className="artist-hold grid grid-col-2">
                        <div className="artist-card">
                            <picture className="image-wrap">
                                <img loading="lazy" src={ArtistImage} alt="Artist" />
                            </picture>
                        </div>
                        <div className="artist-text">
                            <h2>
                                <span className='image-hold'>
                                    <img loading="lazy" src={DoubleArrowBlack} alt="Double Arrow" />
                                </span>
                                The Artist
                            </h2>
                            <div className="text-hold">
                                <h5>OSCAR “SHOCK” GARCÍA</h5>
                                <p>One of the greatest professional wrestling mascareros (mask-makers) of all time, Oscar “Shock” García has designed masks for Rey Mysterio, Penta Zero M, Rey Fénix, Black Taurus, Drago, Lady Shani, and dozens of other wrestling icons. Through Oscar’s inspired virtuosity, every Lucha Mask has been imbued with the authentic spirit of lucha libre history.</p>
                                <ul className="social-icon">
                                    <li>
                                        <a href='https://www.instagram.com/oscarshockgarcia/?hl=en' target="_blank" className='icon-hold'>
                                            <img loading="lazy" src={InstagramDark} alt="Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://twitter.com/OscarShockDG?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target="_blank" className='icon-hold'>
                                            <img loading="lazy" src={TwitterDark} alt="Twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}