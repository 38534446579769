import React, { FC, useState } from 'react';
import './luchadores.scss';
import DoubleArrowWhite from "./../../../assets/images/arrow-white.png";
import LuchaSolar from "./../../../assets/images/lucha-solar.png";
import LuchaTaurus from "./../../../assets/images/lucha-taurus.png";
import LuchaJuventud from "./../../../assets/images/lucha-juventud.png";
import LuchaBaronessa from "./../../../assets/images/lucha-baronessa.png";
import LuchaAeroboy from "./../../../assets/images/lucha-aeroboy.png";
import LuchaDragonLee from "./../../../assets/images/lucha-dragonlee.png";
import LuchaPsychosis from "./../../../assets/images/lucha-psychosis.png";
import LuchaPsychoClown from "./../../../assets/images/lucha-psychoclown.png";
import LuchaPirataMorgan from "./../../../assets/images/lucha-pirata.png";
import LuchaKonnan from "./../../../assets/images/lucha-konnan.png";
import LuchaUltimoDragon from "./../../../assets/images/lucha-ultimo.png";
import LuchaAstroJr from "./../../../assets/images/lucha-astrojr.png";
import LuchaRush from "./../../../assets/images/lucha-rush.png";
import LuchaMatematico from "./../../../assets/images/lucha-matematico.png";
import LuchaLaredo from "./../../../assets/images/lucha-laredo.png";
import LuchaFelino from "./../../../assets/images/lucha-felino.png";
import LuchaTormenta from "./../../../assets/images/lucha-tormenta.png";
import LuchaDorado from "./../../../assets/images/lucha-dorado.png";

interface luchadorProps {
    // 👇️ turn off type checking
    handleOpen: (params: string) => any;

}
export const Luchadores = ({handleOpen}:luchadorProps) => {
    
    return (
        <>
            <section id='luchadores' className="luchadores bg-dark-gradient scroll-top-control text-center abc">
                <div className="container">
                    <h2>
                        <span className='image-hold'>
                            <img loading="lazy" src={DoubleArrowWhite} alt="Double Arrow" />
                        </span>
                        The Luchadores
                    </h2>
                    <ul className="card">
                        <li>
                        <div className="card-hold" onClick={()=>handleOpen("solar")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaSolar} alt="Solar" />
                                </figure>
                                <div className="card-text">
                                    <h5>Solar</h5>
                                    <p>One of the most iconic luchadores in history, Solar was part of the “Los Cadetes del Espacio” trio who helped usher in both the high flying style and trios matches as staples of the sport.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("taurus")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaTaurus} alt="Black Taurus" />
                                </figure>
                                <div className="card-text">
                                    <h5>Black Taurus</h5>
                                    <p>The Black Beast has proven himself a powerful force around the globe. Whether its winning championships or locking horn with former UFC Heavyweight Champ Cain Velasquez, Taurus’ star is on the rise.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("guerrera")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaJuventud} alt="Juventud Guerrera" />
                                </figure>
                                <div className="card-text">
                                    <h5>Juventud Guerrera</h5>
                                    <p>Son of the legendary Fuerza Guerrera, Juvy eclipsed his father on a global scale as a top lucha star in WCW and WWE. One of the most innovative offenses in the game.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("baronessa")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaBaronessa} alt="Baronessa" />
                                </figure>
                                <div className="card-text">
                                    <h5>Baronessa</h5>
                                    <p>The first ever Baja California Women’s Champion and the top luchadora in region over the past five years, Baronessa has started to break out both nationally in Mexico and internationally in the U.S.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("Aeroboy")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaAeroboy} alt="Aeroboy" />
                                </figure>
                                <div className="card-text">
                                    <h5>Aeroboy</h5>
                                    <p>Debuting at only 13 years old, Aeroboy has nearly two decades under his mask. While he’s an extremely talented high flyer, the daredevil is perhaps best known globally for his deathmatch wrestling.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("lee")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaDragonLee} alt="Dragon Lee" />
                                </figure>
                                <div className="card-text">
                                    <h5>Dragon Lee</h5>
                                    <p>One of the biggest stars of the current era, Dragon Lee held multiple titles in CMLL before winning Japan’s NJPW Jr. Heavyweight title and U.S. based Ring of Honor’s World TV and Tag Team titles.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("psychosis")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaPsychosis} alt="Psychosis" />
                                </figure>
                                <div className="card-text">
                                    <h5>Psychosis</h5>
                                    <p>In 1994, Psychosis and Rey Misterio Jr. changed the game of pro wrestling introducing the super fast-paced lucha libre style to Japan and then the U.S. via ECW. Titles in Mexico and the U.S. followed.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("clown")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaPsychoClown} alt="Psycho Clown" />
                                </figure>
                                <div className="card-text">
                                    <h5>Psycho Clown</h5>
                                    <p>He looks like a creature of nightmares but he is the most popular luchador in Mexico today! From the legendary Brazo family, beyond belts his biggest win was taking the mask of Dr. Wagner Jr!</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("morgan")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaPirataMorgan} alt="Pirata Morgan" />
                                </figure>
                                <div className="card-text">
                                    <h5>Pirata Morgan</h5>
                                    <p>One of the biggest 90s stars, known for bloody brawls and scalping his opponents – via winning more than a dozen hair matches. Legit eye patch wearing luchador has held more than a dozen titles too.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("konnan")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaKonnan} alt="Konnan" />
                                </figure>
                                <div className="card-text">
                                    <h5>Konnan</h5>
                                    <p>One of the most influential luchadores in history, not only a top draw in Mexico but he shaped the future of the business pushing luchadores to ECW and WCW. Title reigns include CMLL, AAA, WCW, TNA.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("dragon")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaUltimoDragon} alt="Ultimo Dragon" />
                                </figure>
                                <div className="card-text">
                                    <h5>Ultimo Dragon</h5>
                                    <p>Arguably the greatest Japanese luchador ever, he is best known outside of lucha for holding the J Crown – 10 different junior heavyweight championships at the same time – a record held to this day!</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("astro")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaAstroJr} alt="Super Astro Jr" />
                                </figure>
                                <div className="card-text">
                                    <h5>Super Astro Jr</h5>
                                    <p>Son of the legendary Super Astro, Jr. wowed in CMLL winning Lucha Central’s Rookie of the Year award in 2018. Now Astro Jr. and Solar Jr. will team as “Los Cadetes del Futuro” in the ring and out.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("rush")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaRush} alt="Rush" />
                                </figure>
                                <div className="card-text">
                                    <h5>Rush</h5>
                                    <p>Pure rudo is one of the biggest draws of the decade in Mexico. “El Toro Blanco” Rush won multiple titles in CMLL then joined ROH and won their World Championship. Brother of Dragon Lee & Dralistico.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("matematico")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaMatematico} alt="El Matemático" />
                                </figure>
                                <div className="card-text">
                                    <h5>El Matemático</h5>
                                    <p>Debuting in 1968, El Matemático is a lucha icon. One of four luchador brothers including Hurácan Ramirez and father to four of his own. The man with numbers on his mask has won 11 from others!</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("kid")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaLaredo} alt="Laredo Kid" />
                                </figure>
                                <div className="card-text">
                                    <h5>Laredo Kid</h5>
                                    <p>While he wow’d in his first AAA run from 2005-2011, since 2018 he’s held singles and trios gold and won two major tourneys. One of the best modern era high flyers, he’s proving it now in Impact too.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("felino")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaFelino} alt="Felino Jr." />
                                </figure>
                                <div className="card-text">
                                    <h5>Felino Jr.</h5>
                                    <p>Son of the legendary Felino and member of the Casas lucha dynasty, Felino Jr. has a lot to live up to – but he’s well on his way as one half of the current Mexico National Tag Team Champions in CMLL.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("tormenta")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaTormenta} alt="Chik Tormenta" />
                                </figure>
                                <div className="card-text">
                                    <h5>Chik Tormenta</h5>
                                    <p>Popular independent luchadora made AAA appearances on and off over the last few years until joining the roster full time and teaming with Arez to win the Mixed Tag Team Titles. Bright future ahead!</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="card-hold" onClick={()=>handleOpen("dorado")}>
                                <figure className="image-hold">
                                    <img loading="lazy" src={LuchaDorado} alt="Lince Dorado" />
                                </figure>
                                <div className="card-text">
                                    <h5>Lince Dorado</h5>
                                    <p>A rare masked Puerto Rican luchador he was a top indie standout before being drafted to WWE. Feeling he hit a glass ceiling he asked for his release an now 2022 is poised to be the Year of the Lynx.</p>
                                    <span className='fw-500'>See More</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            
        </>
    );
}