import React, { FC } from 'react';
import './faq.scss';
import DoubleArrowBlack from "./../../../assets/images/arrow-black.svg";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

export const Faq:FC = () => {
    return (
        <>
            <section id='faq' className="faq scroll-top-control bg-white abc">
                <div className="container">
                    <h2>
                        <span className='image-hold'>
                            <img loading="lazy" src={DoubleArrowBlack} alt="Double Arrow" />
                        </span>
                        FAQ
                    </h2>
                    <div className="faq-hold">
                        <Accordion allowZeroExpanded>
                            <AccordionItem uuid="a">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        When is the minting date?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Our minting date is yet to be announced! Once we are ready to make it official, we will announce it on our Discord channel.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="b">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is the mint price?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>To be announced soon!</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="c">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What are Lucha Masks?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>A generative collection of 10,000 NFT luchador wrestling masks, created and authenticated by Masked Republic, Oscar “Shock” García, and Virtua, utilising different traits from some of the world’s top luchador wrestlers.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="d">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How can I get whitelisted for Lucha Masks?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        There are a few different methods of getting whitelisted for the project:
                                        <span className='block'>Method 1: Being a holder of TVK (Terra Virtua Kolect, this is limited to 500 spaces)</span>
                                        <span className='block'>Method 2: Being an active and helpful member of the community</span>
                                        <span className="block">Method 3: Members with the most Discord server invites</span>
                                        <span className="block">Method 4: Through winning game nights held for the community, such as Royal Rumble, quiz nights, and more</span>
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="e">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How many NFTs will there be?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>A total of 10,000. Each will have their own set of traits and rarities.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="f">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Which blockchain will Lucha Masks be on?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Ethereum. We have a large team of amazing devs working on the Lucha Masks smart contract, to keep any gas fees at a minimum.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="g">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is Virtua?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Virtua is a sustainable gamified metaverse platform that provides immersive social, gaming, digital collectible and creative experiences, through NFTs and blockchain technology.
                                        <br/>
                                        <br/>
                                        Lucha Masks is a creative metaverse collaboration between Masked Republic and Virtua.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="h">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is the metaverse?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        The metaverse is like a 3D online video game where you can do anything you feel like doing, from hanging out with friends to exploring new places, buying land, building your own home, playing all kinds of games, and more.
                                        <br/>
                                        <br/>
                                        Virtua is building its own metaverse – and you can see it and learn more at Virtua.com!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="i">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is an NFT?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        An NFT is a digital item that you can buy – and your proof of ownership is recorded on an extremely secure computer network called a blockchain.
                                        <br/>
                                        <br/>
                                        When information is stored on a blockchain, it can’t be changed or deleted or otherwise tampered with. This means that nobody else can pretend that they own your digital items – and you can always prove your ownership if you need to.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem uuid="j">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    How can I use NFTs in the metaverse?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        NFTs enable you to prove that you own the digital items you take into the metaverse – so nobody else in the metaverse will be able to pretend that they own your items. You could put NFT posters on the wall of your home in the metaverse, take an NFT wrestler into a massive arena to compete in an adrenaline-fuelled match, showcase your NFT collection (including your Lucha Masks, of course!), and much more. It all depends on what your digital item is.
                                        <br/>
                                        <br/>
                                        The important thing to bear in mind is that NFTs provide you with a measure of security against people who might otherwise try to pretend that they own your items. This is made possible using blockchain technology, as explained in "What is an NFT?".
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        <div className="contact-us text-center">
                            <div className="text-hold">
                                <h5>Don’t see the answer you need?</h5>
                                <p>That’s ok. Just drop a message and we will get back to you ASAP.</p>
                            </div>
                            <a href='#' type='button' className='btn btn-border-success'>Join Discord</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}