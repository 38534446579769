import React, { FC, useEffect, useState } from 'react';
import Slider from "react-slick";
import './banner.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import JoinProject from "./../../../assets/images/main-dragon-lee.png";
import BannerKonnan from "./../../../assets/images/banner-konnan.png";
import BannerKonnanWebp from "./../../../assets/images/banner-konnan.webp";
import BannerUltimoDragon from "./../../../assets/images/banner-ultimo-dragon.png";
import BannerUltimoDragonWebp from "./../../../assets/images/banner-ultimo-dragon.webp";
import BannerPsychosis from "./../../../assets/images/banner-psychosis.png";
import BannerPsychosisWebp from "./../../../assets/images/banner-psychosis.webp";
import BannerJuventud from "./../../../assets/images/banner-juventud.png";
import BannerJuventudWebp from "./../../../assets/images/banner-juventud.webp";
import BannerPsychoClown from "./../../../assets/images/banner-psycho-clown.png";
import BannerPsychoClownWebp from "./../../../assets/images/banner-psycho-clown.webp";
import BannerSolar from "./../../../assets/images/banner-solar.png";
import BannerSolarWebp from "./../../../assets/images/banner-solar.webp";
import BannerSuperAstro from "./../../../assets/images/banner-super-astro.png";
import BannerSuperAstroWebp from "./../../../assets/images/banner-super-astro.webp";
import BannerDragonLee from "./../../../assets/images/banner-dragon-lee.png";
import BannerDragonLeeWebp from "./../../../assets/images/banner-dragon-lee.webp";
import BannerRush from "./../../../assets/images/banner-rush.png";
import BannerRushWebp from "./../../../assets/images/banner-rush.webp";
import BannerMatematico from "./../../../assets/images/banner-matematico.png";
import BannerMatematicoWebp from "./../../../assets/images/banner-matematico.webp";
import BannerLaredoKid from "./../../../assets/images/banner-laredo-kid.png";
import BannerLaredoKidWebp from "./../../../assets/images/banner-laredo-kid.webp";
import BannerLinceDorado from "./../../../assets/images/banner-lince-dorado.png";
import BannerLinceDoradoWebp from "./../../../assets/images/banner-lince-dorado.webp";
import BannerAeroboy from "./../../../assets/images/banner-aeroboy.png";
import BannerAeroboyWebp from "./../../../assets/images/banner-aeroboy.webp";
import BannerBlackTaurus from "./../../../assets/images/banner-taurus.png";
import BannerBlackTaurusWebp from "./../../../assets/images/banner-taurus.webp";
import BannerFelinoJr from "./../../../assets/images/banner-felino-jr.png";
import BannerFelinoJrWebp from "./../../../assets/images/banner-felino-jr.webp";
import BannerChikaTormenta from "./../../../assets/images/banner-chika-tormenta.png";
import BannerChikaTormentaWebp from "./../../../assets/images/banner-chika-tormenta.webp";
import BannerBaronessa from "./../../../assets/images/banner-baronesa.png";
import BannerBaronessaWebp from "./../../../assets/images/banner-baronesa.webp";
import InstagramDark from "./../../../assets/images/instagram-dark.svg";
import TwitterDark from "./../../../assets/images/twitter-dark.svg";
import KonnanFace from "./../../../assets/images/konnan-face.png";
import UltimoDragFace from "./../../../assets/images/ultimo-dragon-face.png";
import PsychosisFace from "./../../../assets/images/psychosis-face.png";
import JuventudFace from "./../../../assets/images/juventud-face.png";
import PsychoClownFace from "./../../../assets/images/psycho-clown-face.png";
import SolarFace from "./../../../assets/images/solar-face.png";
import SuperAstroFace from "./../../../assets/images/super-astro-face.png";
import DragonLeeFace from "./../../../assets/images/dragon-lee-face.png";
import RushFace from "./../../../assets/images/rush-face.png";
import MatematicoFace from "./../../../assets/images/matematico-face.png";
import LaredoKidFace from "./../../../assets/images/laredo-kid-face.png";
import LinceDoradoFace from "./../../../assets/images/lince-dorado-face.png";
import AeroboyFace from "./../../../assets/images/aeroboy-face.png";
import BlackTaurusFace from "./../../../assets/images/torus-face.png";
import FelinoJrFace from "./../../../assets/images/felino-jr-face.png";
import ChikaTormentaFace from "./../../../assets/images/chika-tormenta-face.png";
import BaronessaFace from "./../../../assets/images/baronesa-face.png";
import { NextGenImages } from '../../../components/nextgen-images/NextGenImages';

export const Banner:FC = () => {
   let [sklider,setSlider] =  useState<any>({
        nav1: null,
        nav2: null
      })
      let slider1:any;
      let slider2:any;
     useEffect(()=>{
        setSlider({
            nav1:slider1,
            nav2:slider2,
        })
     },[])
     let navbannersett = {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // fade: true,
        lazyLoad: true
     };
     let navsettings = {
        arrows: false,
        focusOnSelect: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1

                }
            }
        ]
    };
    return (
        <>
        <section id='home' className="banner">
        <div className='main-sec'>
                    <div className="container">
                        <div className="main-sec-hold">
                        <figure className='main-sec-img image-hold'>
                            <img src={JoinProject} alt="Join Project" />
                        </figure>
                        <div className="main-sec-text">
                            <p>Generative luchador wrestling NFTs, authenticated by Masked Republic, Oscar “Shock” García, and Virtua. Join the genesis of the Metaverse Wrestling Federation – rooted in the legacies of world-class professional wrestlers – and access perks including access to physical-world events, meet-and-greet sessions, exclusive drops and merch, and much more!</p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="banner-hold">
                    <div className="container">
                        <div className="banner-wrap">
                            <Slider asNavFor={sklider.nav2}  ref={slider => (slider1 = slider)} {...navbannersett}>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1 itemProp='headline'>
                                            <span className='inline-block'>Konnan</span>
                                        </h1>
                                        <p>One of the most influential luchadores in history, not only a top draw in Mexico but he shaped the future of the business pushing luchadores to ECW and WCW. Title reigns include CMLL, AAA, WCW, TNA.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerKonnanWebp}
                                    fallback={BannerKonnan}
                                    alt="Konnan"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Ultimo Dragon</span>
                                        </h1>
                                        <p>Arguably the greatest Japanese luchador ever, he is best known outside of lucha for holding the J Crown – 10 different junior heavyweight championships at the same time – a record held to this day!</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerUltimoDragonWebp}
                                    fallback={BannerUltimoDragon}
                                    alt="Ultimo Dragon"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Psychosis</span>
                                        </h1>
                                        <p>In 1994, Psychosis and Rey Misterio Jr. changed the game of pro wrestling introducing the super fast-paced lucha libre style to Japan and then the U.S. via ECW. Titles in Mexico and the U.S. followed.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerPsychosisWebp}
                                    fallback={BannerPsychosis}
                                    alt="Psychosis"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Juventud Guerrera</span>
                                        </h1>
                                        <p>Son of the legendary Fuerza Guerrera, Juvy eclipsed his father on a global scale as a top lucha star in WCW and WWE. One of the most innovative offenses in the game.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerJuventudWebp}
                                    fallback={BannerJuventud}
                                    alt="Juventud Guerrera"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Psycho Clown</span>
                                        </h1>
                                        <p>He looks like a creature of nightmares but he is the most popular luchador in Mexico today! From the legendary Brazo family, beyond belts his biggest win was taking the mask of Dr. Wagner Jr!</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerPsychoClownWebp}
                                    fallback={BannerPsychoClown}
                                    alt="Psycho Clown"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Solar</span>
                                        </h1>
                                        <p>One of the most iconic luchadores in history, Solar was part of the “Los Cadetes del Espacio” trio who helped usher in both the high flying style and trios matches as staples of the sport.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerSolarWebp}
                                    fallback={BannerSolar}
                                    alt="Solar"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Super Astro Jr</span>
                                        </h1>
                                        <p>Son of the legendary Super Astro, Jr. wowed in CMLL winning Lucha Central’s Rookie of the Year award in 2018. Now Astro Jr. and Solar Jr. will team as “Los Cadetes del Futuro” in the ring and out.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerSuperAstroWebp}
                                    fallback={BannerSuperAstro}
                                    alt="Super Astro Jr"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Dragon Lee</span>
                                        </h1>
                                        <p>One of the biggest stars of the current era, Dragon Lee held multiple titles in CMLL before winning Japan’s NJPW Jr. Heavyweight title and U.S. based Ring of Honor’s World TV and Tag Team titles.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerDragonLeeWebp}
                                    fallback={BannerDragonLee}
                                    alt="Dragon Lee"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Rush</span>
                                        </h1>
                                        <p>Pure rudo is one of the biggest draws of the decade in Mexico. “El Toro Blanco” Rush won multiple titles in CMLL then joined ROH and won their World Championship. Brother of Dragon Lee & Dralistico.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerRushWebp}
                                    fallback={BannerRush}
                                    alt="Rush"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>El Matemático</span>
                                        </h1>
                                        <p>Debuting in 1968, El Matemático is a lucha icon. One of four luchador brothers including Hurácan Ramirez and father to four of his own. The man with numbers on his mask has won 11 from others!</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerMatematicoWebp}
                                    fallback={BannerMatematico}
                                    alt="El Matemático"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Laredo Kid</span>
                                        </h1>
                                        <p>While he wow’d in his first AAA run from 2005-2011, since 2018 he’s held singles and trios gold and won two major tourneys. One of the best modern era high flyers, he’s proving it now in Impact too.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerLaredoKidWebp}
                                    fallback={BannerLaredoKid}
                                    alt="Laredo Kid"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Lince Dorado</span>
                                        </h1>
                                        <p>A rare masked Puerto Rican luchador he was a top indie standout before being drafted to WWE. Feeling he hit a glass ceiling he asked for his release an now 2022 is poised to be the Year of the Lynx.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerLinceDoradoWebp}
                                    fallback={BannerLinceDorado}
                                    alt="Lince Dorado"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Aeroboy</span>
                                        </h1>
                                        <p>Debuting at only 13 years old, Aeroboy has nearly two decades under his mask. While he’s an extremely talented high flyer, the daredevil is perhaps best known globally for his deathmatch wrestling.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerAeroboyWebp}
                                    fallback={BannerAeroboy}
                                    alt="Aeroboy"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Black Taurus</span>
                                        </h1>
                                        <p>The Black Beast has proven himself a powerful force around the globe. Whether its winning championships or locking horn with former UFC Heavyweight Champ Cain Velasquez, Taurus’ star is on the rise.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerBlackTaurusWebp}
                                    fallback={BannerBlackTaurus}
                                    alt="Black Taurus"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Felino Jr.</span>
                                        </h1>
                                        <p>Son of the legendary Felino and member of the Casas lucha dynasty, Felino Jr. has a lot to live up to – but he’s well on his way as one half of the current Mexico National Tag Team Champions in CMLL.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerFelinoJrWebp}
                                    fallback={BannerFelinoJr}
                                    alt="Felino Jr."
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Chik Tormenta</span>
                                        </h1>
                                        <p>Popular independent luchadora made AAA appearances on and off over the last few years until joining the roster full time and teaming with Arez to win the Mixed Tag Team Titles. Bright future ahead!</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerChikaTormentaWebp}
                                    fallback={BannerChikaTormenta}
                                    alt="Chika Tormenta"
                                    />
                                </div>
                                <div className="slider-content">
                                    <div className="banner-text">
                                        <h1>
                                            <span className='inline-block'>Baronessa</span>
                                        </h1>
                                        <p>The first ever Baja California Women’s Champion and the top luchadora in region over the past five years, Baronessa has started to break out both nationally in Mexico and internationally in the U.S.</p>
                                        <div className="btn-icon-hold flex">
                                            {/* <button className='btn btn-success' type='button'>Buy on Opensea</button> */}
                                            <ul className='banner-icon flex'>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={InstagramDark} alt="Instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={TwitterDark} alt="Twitter" />
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='#' target="_blank" className='nav-icon-hold'>
                                                        <img src={DiscordDark} alt="Discord" />
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <NextGenImages 
                                    classname="image-hold"
                                    src={BannerBaronessaWebp}
                                    fallback={BannerBaronessa}
                                    alt="Baronessa"
                                    />
                                </div>
                            </Slider>
                            <div className='slider-nav bg-white'>
                                <Slider asNavFor={sklider.nav1} ref={slider => (slider2 = slider)} {...navsettings}>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img src={KonnanFace} alt="Konnan" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Konnan</span>
                                            <small>Rarity: #01</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img src={UltimoDragFace} alt="Ultimo Dragon" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Ultimo Dragon</span>
                                            <small>Rarity: #02</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img src={PsychosisFace} alt="Psychosis" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Psychosis</span>
                                            <small>Rarity: #02</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img src={JuventudFace} alt="Juventud Guerrera" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Juventud Guerrera</span>
                                            <small>Rarity: #03</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={PsychoClownFace} alt="Psycho Clown" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Psycho Clown</span>
                                            <small>Rarity: #03</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={SolarFace} alt="Solar" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Solar</span>
                                            <small>Rarity: #04</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={SuperAstroFace} alt="Super Astro Jr" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Super Astro Jr</span>
                                            <small>Rarity: #04</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={DragonLeeFace} alt="Dragon Lee" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Dragon Lee</span>
                                            <small>Rarity: #05</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={RushFace} alt="Rush" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Rush</span>
                                            <small>Rarity: #05</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={MatematicoFace} alt="El Matematico" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>El Matemático</span>
                                            <small>Rarity: #05</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={LaredoKidFace} alt="Laredo Kid" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Laredo Kid</span>
                                            <small>Rarity: #06</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={LinceDoradoFace} alt="Lince Dorado" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Lince Dorado</span>
                                            <small>Rarity: #06</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={AeroboyFace} alt="Aeroboy" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Aeroboy</span>
                                            <small>Rarity: #06</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={BlackTaurusFace} alt="Black Taurus" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Black Taurus</span>
                                            <small>Rarity: #07</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={FelinoJrFace} alt="Felino Jr." />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Felino Jr.</span>
                                            <small>Rarity: #07</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={ChikaTormentaFace} alt="Chika Tormenta" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Chik Tormenta</span>
                                            <small>Rarity: #08</small>
                                        </div>
                                    </div>
                                    <div className="slider-nav-hold">
                                        <figure className='image-hold'>
                                            <figure className='image-wrap'>
                                                <img loading="lazy" src={BaronessaFace} alt="Baronessa" />
                                            </figure>
                                        </figure>
                                        <div className='text-hold'>
                                            <span className='block'>Baronessa</span>
                                            <small>Rarity: #09</small>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        </>
    );
}
